import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { userProfile } from '../profile';
import { db } from '../firebaseInit';

export class Organization {

	static async setActiveOrg() {
		if (!userProfile.activeOrgId) {
			console.log("no active org to set");
			return;
		}
		// check local storage first. if it already matches the id set in the user profile don't load from db
		let activeOrg = JSON.parse(sessionStorage.getItem('activeOrg'));
		if (activeOrg && (activeOrg.id == userProfile.id)) {
			console.log("local org already exists..using that one.");
			return;
		}
		// get from db and set to session if it isn't in session
		console.log("getting activeOrg from db");
		const orgRef = doc(db, 'organizations', userProfile.activeOrgId);
		const orgSnap = await getDoc(orgRef);
		activeOrg = orgSnap.data();
		sessionStorage.setItem('activeOrg', JSON.stringify(activeOrg));
	}

	static async getActiveOrg() {
		if (!userProfile.activeOrgId) {
			console.log("no active org");
			return;
		}
		// check local storage first
		let activeOrg = JSON.parse(sessionStorage.getItem('activeOrg'));
		// get from db and set to session if it isn't in session
		if (!activeOrg) { 
			console.log("get activeOrg from db");
			const orgRef = doc(db, 'organizations', userProfile.activeOrgId);
			const orgSnap = await getDoc(orgRef);
			activeOrg = orgSnap.data();
			sessionStorage.setItem('activeOrg', JSON.stringify(activeOrg));
		} else {
			console.log("activeOrg found in session");
		}
		return activeOrg;
	}

	static async saveActiveOrg(org) {
		if (!userProfile.activeOrgId) {
			console.log("no active org");
			return;
		}
		sessionStorage.setItem('activeOrg', JSON.stringify(org));
		const orgRef = doc(db, 'organizations', userProfile.activeOrgId);
		await updateDoc(orgRef, org);

	}
}